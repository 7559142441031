exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industries-automotive-tsx": () => import("./../../../src/pages/industries/automotive.tsx" /* webpackChunkName: "component---src-pages-industries-automotive-tsx" */),
  "component---src-pages-industries-retail-arbitrage-tsx": () => import("./../../../src/pages/industries/retail-arbitrage.tsx" /* webpackChunkName: "component---src-pages-industries-retail-arbitrage-tsx" */),
  "component---src-pages-industries-second-hand-clothing-tsx": () => import("./../../../src/pages/industries/second-hand-clothing.tsx" /* webpackChunkName: "component---src-pages-industries-second-hand-clothing-tsx" */),
  "component---src-pages-industries-tsx": () => import("./../../../src/pages/industries.tsx" /* webpackChunkName: "component---src-pages-industries-tsx" */),
  "component---src-pages-integrations-tsx": () => import("./../../../src/pages/integrations.tsx" /* webpackChunkName: "component---src-pages-integrations-tsx" */),
  "component---src-pages-privacy-mobile-tsx": () => import("./../../../src/pages/privacy-mobile.tsx" /* webpackChunkName: "component---src-pages-privacy-mobile-tsx" */),
  "component---src-pages-solutions-ai-clothing-visualisation-tsx": () => import("./../../../src/pages/solutions/ai-clothing-visualisation.tsx" /* webpackChunkName: "component---src-pages-solutions-ai-clothing-visualisation-tsx" */),
  "component---src-pages-solutions-ai-publisher-tsx": () => import("./../../../src/pages/solutions/ai-publisher.tsx" /* webpackChunkName: "component---src-pages-solutions-ai-publisher-tsx" */),
  "component---src-pages-solutions-automatic-publication-system-tsx": () => import("./../../../src/pages/solutions/automatic-publication-system.tsx" /* webpackChunkName: "component---src-pages-solutions-automatic-publication-system-tsx" */),
  "component---src-pages-solutions-fulfillment-platform-tsx": () => import("./../../../src/pages/solutions/fulfillment-platform.tsx" /* webpackChunkName: "component---src-pages-solutions-fulfillment-platform-tsx" */),
  "component---src-pages-solutions-keeper-mobile-app-tsx": () => import("./../../../src/pages/solutions/keeper-mobile-app.tsx" /* webpackChunkName: "component---src-pages-solutions-keeper-mobile-app-tsx" */),
  "component---src-pages-solutions-online-stores-tsx": () => import("./../../../src/pages/solutions/online-stores.tsx" /* webpackChunkName: "component---src-pages-solutions-online-stores-tsx" */),
  "component---src-pages-solutions-sorting-app-station-tsx": () => import("./../../../src/pages/solutions/sorting-app-station.tsx" /* webpackChunkName: "component---src-pages-solutions-sorting-app-station-tsx" */),
  "component---src-pages-solutions-tsx": () => import("./../../../src/pages/solutions.tsx" /* webpackChunkName: "component---src-pages-solutions-tsx" */)
}

